
import {Component, Watch, Vue} from "vue-property-decorator";
import SplitLayout from "../components/SplitLayout.vue";
import InlineDropdown from "@/components/InlineDropdown.vue";
import GTDTools from "@/components/GTDTools.vue";
import BackNavigation from "@/components/BackNavigation.vue";

enum SalaryPeriod {
  HOUR = "hour",
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  YEAR = "year"
}

enum SupportedCurrency {
  EUR = "€",
  GBP = "£",
  USD = "$",
  NOK = "kr",
  CHF = "CHF",
  RUB = "₽",
  JPY = "¥",
  CNY = "¥ /元"
}

enum MeetingFrequency {
  ONE_TIME = "one-time",
  DAILY = "daily",
  WEEKLY = "weekly"
}

interface UrlParams {
  c: SupportedCurrency;
  a: number;
  p: SalaryPeriod;
  f: MeetingFrequency;
  h: number;
  n: number;
}

@Component({
  components: {InlineDropdown, SplitLayout, GTDTools, BackNavigation}
})
export default class BusinessImpact extends Vue {
  private readonly Currencies = SupportedCurrency
  private readonly MeetingFrequency = MeetingFrequency
  private readonly SalaryPeriods = Object.values(SalaryPeriod)

  salaryCurrency: SupportedCurrency = SupportedCurrency.EUR

  salaryAmount = 50

  salaryPeriod: SalaryPeriod = SalaryPeriod.HOUR

  meetingFrequency: MeetingFrequency = MeetingFrequency.WEEKLY

  meetingHours = 1

  meetingParticipants = 6

  @Watch('salaryCurrency')
  @Watch('salaryAmount')
  @Watch('salaryPeriod')
  @Watch('meetingFrequency')
  @Watch('meetingHours')
  @Watch('meetingParticipants')
  paramsChange() {
    this.setUrlParams()
  }

  mounted() {
    const params = this.$route.query.settings

    if (params) this.readUrlParams(params.toString())
    else this.setUrlParams()
  }

  public get meetingCost() {
    // calculations assume 8 hours a day, 5 days a week, 4 weeks a month, 12 month a year
    let hourlyRate = this.salaryAmount
    if (this.salaryPeriod === SalaryPeriod.DAY) hourlyRate /= 8
    else if (this.salaryPeriod === SalaryPeriod.WEEK) hourlyRate /= 8 * 5
    else if (this.salaryPeriod === SalaryPeriod.MONTH) hourlyRate /= 8 * 5 * 4
    else if (this.salaryPeriod === SalaryPeriod.YEAR) hourlyRate /= 8 * 5 * 4 * 12

    let meetingsPerMonth = 1
    if (this.meetingFrequency === MeetingFrequency.WEEKLY) meetingsPerMonth = 4
    if (this.meetingFrequency === MeetingFrequency.DAILY) meetingsPerMonth = 5 * 4

    return Math.round(hourlyRate * this.meetingHours * this.meetingParticipants * meetingsPerMonth)
  }

  public get salaryCaption() {
    return `${this.salaryCurrency}${this.salaryAmount}/${this.salaryPeriod}`
  }

  public get meetingLengthCaption() {
    const hourLabel = this.meetingHours.toString() === '1' ? 'hour' : 'hours'
    return `${this.meetingHours} ${hourLabel}`
  }

  public readUrlParams(params: string) {
    const dataObject: UrlParams = JSON.parse(decodeURIComponent(escape(atob(params))))

    this.salaryCurrency = dataObject.c
    this.salaryAmount = dataObject.a
    this.salaryPeriod = dataObject.p
    this.meetingFrequency = dataObject.f
    this.meetingHours = dataObject.h
    this.meetingParticipants = dataObject.n
  }

  public setUrlParams() {
    const dataObject: UrlParams = {
      c: this.salaryCurrency,
      a: this.salaryAmount,
      p: this.salaryPeriod,
      f: this.meetingFrequency,
      h: this.meetingHours,
      n: this.meetingParticipants
    }

    const dataLine = btoa(unescape(encodeURIComponent(JSON.stringify(dataObject))))

    const newUrl = '/business-impact/?settings=' + dataLine
    this.$router.replace(newUrl)
  }
}
